/**
* Template Name: ComingSoon
* Template URL: https://bootstrapmade.com/comingsoon-free-html-bootstrap-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

  
  a {
    color: #ff0000;
    text-decoration: none;
  }
  
  a:hover {
    color: #ff3333;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
  
  #main {
    position: relative;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #ff0000;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #ff2929;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    position: relative;
    width: 100%;
    padding: 100px 0;
    /* countdown */
  }
  
  #header h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  }
  
  #header h2 {
    color: #eee;
    margin-bottom: 40px;
    font-size: 22px;
  }
  
  #header .countdown {
    margin-bottom: 80px;
  }
  
  #header .countdown div {
    text-align: center;
    margin: 10px;
    width: 100px;
    padding: 15px 0;
    background: rgba(255, 255, 255, 0.12);
    border-top: 5px solid #ff0000;
  }
  
  #header .countdown div h3 {
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 15px;
  }
  
  #header .countdown div h4 {
    font-size: 16px;
    font-weight: 600;
  }
  
  @media (max-width: 575px) {
    #header .countdown div {
      width: 70px;
      padding: 10px 0;
      margin: 10px 8px;
    }
  
    #header .countdown div h3 {
      font-size: 28px;
      margin-bottom: 10px;
    }
  
    #header .countdown div h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }
  
  #header .subscribe {
    font-size: 15px;
    text-align: center;
  }
  
  #header .subscribe h4 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #header .subscribe .subscribe-form {
    min-width: 300px;
    margin-top: 10px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    text-align: left;
  }
  
  #header .subscribe .subscribe-form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #header .subscribe .subscribe-form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #ff0000;
    color: #fff;
    transition: 0.3s;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header .subscribe .subscribe-form input[type=submit]:hover {
    background: #f50000;
  }
  
  #header .subscribe .error-message {
    display: none;
    color: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  #header .subscribe .sent-message {
    display: none;
    color: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  #header .subscribe .loading {
    display: none;
    text-align: center;
    padding: 15px;
  }
  
  #header .subscribe .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading-notify 1s linear infinite;
  }
  
  @keyframes animate-loading-notify {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  #header .social-links {
    margin-top: 10px;
  }
  
  #header .social-links a {
    font-size: 24px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    padding-top: 14px;
    margin: 0 10px;
    text-align: center;
    transition: 0.3s;
  }
  
  #header .social-links a:hover {
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
  }
  
  .section-bg {
    background-color: #fff5f5;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 15px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .about .content h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .about .content h3 {
    font-weight: 500;
    line-height: 32px;
    font-size: 24px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 10px 0 0 28px;
    position: relative;
  }
  
  .about .content ul i {
    left: 0;
    top: 12px;
    position: absolute;
    font-size: 20px;
    color: #ff0000;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .contact .info-wrap {
    background: rgba(255, 255, 255, 0.06);
    padding: 30px;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #fff;
    float: left;
    width: 44px;
    height: 44px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .contact .info .email p {
    padding-top: 5px;
  }
  
  .contact .info .social-links {
    padding-left: 60px;
  }
  
  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }
  
  .contact .info .social-links a:hover {
    background: #ff0000;
    color: #fff;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #ff0000;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    padding: 30px;
    padding-top: 0;
    background: rgba(255, 255, 255, 0.06);
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  
  .contact .php-email-form input::-moz-placeholder,
  .contact .php-email-form textarea::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
  
  .contact .php-email-form input::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #ff0000;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #ff3333;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: rgba(19, 19, 19, 0.4);
    padding: 30px 0;
    color: #fff;
    font-size: 14px;
    position: relative;
  }
  
  #footer .copyright {
    text-align: center;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .credits a {
    color: #ff3333;
  }